import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Selfserve6 } from '../../../components/images/support/workflows/Selfserve6.generated';
import { Selfserve7 } from '../../../components/images/support/workflows/Selfserve7.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "start-a-self-service-workflow"
    }}>{`Start a Self Service Workflow`}</h1>
    <p>{`Follow this guide to start a self-service workflow.`}</p>
    <p>{`Before starting ensure that the business has at least one self-service workflow configured.`}</p>
    <p>{`Learn how to do that `}<a parentName="p" {...{
        "href": "/support/workflows/create-self-service-workflow"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Lets get started!`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the Dashboard and click the `}<strong parentName="li">{`Start workflow`}</strong>{` button.`}</li>
    </ol>

    <Selfserve6 mdxType="Selfserve6" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Select the workflow you wish to start.`}</li>
    </ol>

    <Selfserve7 mdxType="Selfserve7" />
    <h1 {...{
      "id": "great-job-you-have-now-started-a-self-service-workflow-"
    }}>{`Great job you have now started a self-service workflow 🎉`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      